export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore()
  const orderStore = useOrderStore()
  const device = useDevice()
  const { $toast } = useNuxtApp()

  if (useAppStore().merchant.auth.required && !authStore.isAuthenticated) {
    if (device.isDesktop) {
      authStore.authModal = true
      return navigateTo(to.path == from.path ? '/' : from)
    } else {
      return navigateTo('/login')
    }
  }

  if (orderStore.isDelivery && !orderStore.deliveryAddressIsValid) {
    $toast({
      type: 'error',
      title: 'Пожалуйста, укажите адрес доставки',
      description: 'Чтобы перейти к оформлению заказа',
    })

    if (device.isDesktop) {
      orderStore.addressModal = true
      return navigateTo('/')
    } else {
      return navigateTo('/address')
    }
  }

  if (orderStore.isPickup && !orderStore.selectedPickupPoint) {
    $toast({
      type: 'error',
      title: 'Пожалуйста, выберите точку самовывоза',
      description: 'Чтобы перейти к оформлению заказа',
    })

    if (device.isDesktop) {
      orderStore.addressModal = true
      return navigateTo('/')
    } else {
      return navigateTo('/address')
    }
  }

  if (orderStore.isDinein && !orderStore.selectedDineinPoint) {
    $toast({
      type: 'error',
      title: 'Пожалуйста, выберите заведение',
      description: 'Чтобы перейти к оформлению заказа',
    })

    if (device.isDesktop) {
      orderStore.addressModal = true
      return navigateTo('/')
    } else {
      return navigateTo('/address')
    }
  }
})
